<template>
  <b-row class="match-height m-0">
    <card-creator :key="change" class="w-card-330-left p-0"></card-creator>
    <b-col>
      <b-card>
       <b-row class="justify-content-start d-flex mb-1">
          <b-tabs 
            content-class="p-0" 
            active-nav-item-class="campaign-tabs-principal-active-dashboard"
            id="tabs-dashboad-creator"
          >
            <b-tab :title="$t('dashboard.overview')"></b-tab>
            <b-tab title-link-class="campaign-tabs-principal" :title="$t('dashboard.proposals')" @click="$router.push({name: 'my_work', params: { section: 'proposals' } })"></b-tab>
            <b-tab title-link-class="campaign-tabs-principal" :title="$t('dashboard.pitchesCampaigns')"  @click="$router.push({name: 'my_work', params: { section: 'pitches' } })"></b-tab>
            <b-tab title-link-class="campaign-tabs-principal" :title="$t('dashboard.transactions')" @click="$router.push({name: 'my_work', params: { section: 'transactions' } })"></b-tab>
          </b-tabs>
        </b-row>
        <desk></desk>
        <card-go-pro class="col-12 p-0"
            :textBadge="$t('creator.textGoPro')"
            :textInfo="$t('creator.decreasesCommission')"
            :link="$t('creator.updatedToday')"
            :img="imgNetwork('influencer_BM')"
            :reference="referenceMember"
          />
      </b-card>
    </b-col>
    
    <div class="w-card-330-right p-0">
      <b-button 
        class="blue-button w-100"  
        variant="blue-button"        
        @click="redirectOption()"
        >{{ $t("dashboard.createProposal") }}
      </b-button>

      <b-card class="card-apply-job mt-1 h-100" footer-class="without-border">

        <!-- payment  -->
        <div class="bg-light-primary rounded-card-up content-items">
          <div>
            <h5>{{$t('dashboard.titleBalanceBrand')}} 
              <b-icon :icon="viewEye ? 'eye-fill' : 'eye-slash-fill'" class="cursor-pointer" @click="changeEye"/>
            </h5>
          </div>
          <div class="items-content-balance">
            <h2 class="text-body-price">
              $ {{ viewEye ? numberFormat(total_ready,2) : 
                numberFormat(total_ready,2).toString().replace(/[0-9,.]/g, '*')}}  <span class="text-usd">{{ $t("dashboard.balance") }}</span>
            </h2>
          </div>
        </div>
        
        <!--/ payment  -->
        <!-- <h4 class="apply-job-title mt-2 mb-1">
          {{ $t("dashboard.titleBalance") }}
        </h4>
        <b-card-text class="mt-2" v-html="$t('dashboard.textBalance')">
        </b-card-text> -->

        <div class="bg-light-light rounded-card-down pl-1">
          <span>
            $ {{ viewEye ? decimalFixed(balance_withdrawal, 2) +' USD '+ $t('dashboard.retiredHistorical') : 
              decimalFixed(Math.abs(numberFormat(balance_withdrawal)), 2).toString().replace(/[0-9,.]/g, '*') +' USD '+ $t('dashboard.retiredHistorical')}}
          </span>
        </div>

        <template #footer>
          <b-button
            block
            variant="outline-primary"
            @click="$router.push({name: 'my_work', params: { section: 'transactions' } })"
          >
            {{ $t("dashboard.goToTransacction") }}
          </b-button>
        </template>
      </b-card>
    </div>
    <b-col class="col-12 px-0 mb-2">
      <div class="container-loader-casting loader-casting" v-if="!loaded_casting_calls">
        <b-spinner class="spinner-casting"></b-spinner>
      </div>
        <div class="box-casting-calls">
          <div class="col-12 col-md-3 mb-1 mb-md-0">
            <h1>{{ $t('creator.interest_campaigns') }}</h1>
            <b-button variant="light" @click="$router.push({name: 'casting_calls'})">{{ $t('creator.viewMore') }}</b-button>
          </div>
          <div class="col-12 col-md-9">
            <vue-horizontal responsive class="horizontal horizontal-gray-casting-calls" :displacement="0.3" snap="end" v-if="loaded_casting_calls">
              <card-casting-call v-for="casting_call in casting_calls" :key="casting_call.uuid" :casting_call="casting_call"></card-casting-call>
            </vue-horizontal>
          </div>
        </div>
      </b-col>

    <b-col cols="12" class="mt-5" v-if="!groupRol">
      <b-card class="banner-leads">
        <feather-icon icon="CreditCardIcon" size="40" class="icon-leads"/>
        <h1 class="ml-2">{{ $t("dashboard.selectTool") }}</h1>
      </b-card>
    </b-col>

    <b-row  class="match-height" v-if="!groupRol">
      <b-col
        cols="12"
        class="col-lg-4 col-md-6 col-sm-12"
        v-for="(cardInfo, index) in cardsCreator"
        :key="index"
      >
        <b-link
          :href="cardInfo.href"
          target="_blank"
          :class="
            cardInfo.status === 'inactive'
              ? 'link-disabled all-link'
              : 'all-link'
          "
          :disabled="cardInfo.status !== 'active'"
        >
          <b-card
            v-if="cardInfo.img"
            class="hoverCard"
            header-class="header-card-creator"
            :img-src="cardInfo.img"
            :img-alt="cardInfo.title"
            img-top
          >
            <b-card-title class="mt-2 title-card"
              >{{ $t(cardInfo.title) }}
              <b-badge
                v-if="cardInfo.alert"
                :id="'tooltip-creator-' + cardInfo.id"
                variant="warning"
              >
                <feather-icon icon="InfoIcon" size="10" /> </b-badge
            ></b-card-title>
            <b-card-text class="text-black">
              {{ $t(cardInfo.description) }}
            </b-card-text>
          </b-card>
          <b-card
            v-else
            :header-bg-variant="getVariantBg(cardInfo)"
            header=" "
            header-class="header-card-creator"
          >
            <b-card-title class="mt-2 title-card">{{
              $t(cardInfo.title)
            }}</b-card-title>
            <b-card-text class="black">
              {{ $t(cardInfo.description) }}
            </b-card-text>
          </b-card>
        </b-link>
        <b-tooltip
          v-if="cardInfo.alert"
          :target="'tooltip-creator-' + cardInfo.id"
          triggers="hover"
          placement="right"
        >
          {{ $t(cardInfo.alert_text) }}
        </b-tooltip>
      </b-col>
    </b-row>

    <div v-if="groupRol === 'creator'" class="col-12">
      <home-banner
        :imageName="homeBanner.imageName"
        :title="homeBanner.title"
        :textContent="homeBanner.textContent"
        :buttonName="homeBanner.buttonName"
        :btn_action="openModal"
      ></home-banner>
    </div>
    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      @updated_network="changeNet"
      :show_sider_network="show_sider_network"
      v-if="show_sider_network"
    />
  </b-row>
  
</template>
    
<script>
import {
  BSpinner,
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BCardTitle,
  BBadge,
  BTooltip,
  BButton,
  BIcon,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import service_leads from '@/services/leads'
import utils_leads from '@/libs/leads'
import service from "@/services/dashboard";
import siderNetwork from "@/views/pages/proposals/stepper/siderNetwork.vue";
import { getUrlCategory } from '@/libs/utils/urls';
import { hasPermission, getUserData } from '@/libs/utils/user';
import { decimalFixed, numberFormat } from '@/libs/utils/formats';
import casting_calls_services from '@/services/casting_calls';
import VueHorizontal from 'vue-horizontal';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: "DashboardCreator",
  components: {
    cardCastingCall: () => import('@/views/components/casting-calls/cardCastingCall.vue'),
    BSpinner,
    VueHorizontal,
    siderNetwork,
    BTab,
    BTabs,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BBadge,
    BTooltip,
    BButton,
    BIcon,
    cardGoPro: () => import('@/views/components/proposals/cardGoPro.vue'),
    desk: () => import('@/views/pages/proposals/desk.vue'),
    CardCreator: () => import('@/views/dashboard/analytics/CardCreator.vue'),
    HomeBanner: () => import('@/views/components/banner/HomeBanner.vue'),
  },
  data() {
    return {
      create_proposal: getUserData().meta_info,
      balance_withdrawal: 0,
      loaded_casting_calls: false,
      casting_calls: [],
      decimalFixed,
      numberFormat,
      change: false,
      show_sider_network: false,
      viewEye: false,
      total_balance: 0,
      data: {},
      service_leads,
      participation_lead: null,
      utils_leads,
      datasuggest: [],
      filteredOptions: [],
      waiting_balance: 0,
      program_withdrawal_balance: 0,
      total_ready: 0,
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: 'Type "e"',
      },
      cardsCreator: [
        {
          id: 1,
          title: "dashboard.crowdmissions",
          img: require("@/assets/images/dashboard/creator/crowdmissions.png"),
          description: "dashboard.descriptionCrowdmissions",
          href: null,
          variant: "primary",
          alert: "warning",
          alert_text: "dashboard.alertCrowdmissions",
          status: "inactive",
        },
        {
          id: 2,
          title: "dashboard.leads",
          img: require("@/assets/images/dashboard/creator/brandme-leads.png"),
          description: "dashboard.descriptionLeads",
          href: "/leads",
          variant: "warning",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 3,
          title: "dashboard.shop",
          img: require("@/assets/images/dashboard/creator/brandme-shop.png"),
          description: "dashboard.descriptionShop",
          href: "https://shop.brandme.la/",
          variant: "info",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 4,
          title: "dashboard.talent",
          img: require("@/assets/images/dashboard/creator/brandme-talent.png"),
          description: "dashboard.descriptionTalent",
          href: "https://brandme.la/brandme-talent/",
          variant: "secondary",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 5,
          title: "dashboard.tools",
          img: require("@/assets/images/dashboard/creator/freetools.png"),
          description: "dashboard.descriptionFreetools",
          href: "https://brandme.la/free-tools/",
          variant: "success",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 6,
          title: "dashboard.mediaKit",
          img: require("@/assets/images/dashboard/creator/media-kit.png"),
          description: "dashboard.descriptionMediaKit",
          href: null,
          variant: "primary",
          alert: null,
          alert_text: null,
          status: "inactive",
        },
      ],
      homeBanner: {
        imageName: "bannerButtonImage.png",
        title: "dashboard.bannerTitle",
        textContent: "dashboard.bannerContent",
        buttonName: "dashboard.buttonName",
        link: "pages/account-setting/Social",
      },
      limit: 10,
      campaign: {},
      campaignView: false,
      downImg: require("@/assets/images/banner/banner_brand.png"),
      imgCreator: require("@/assets/images/banner/banner_creator.png"),
      hRol: "",
      width: window.screen.width,
    };
  },
  created() {
    this.getGroup();
    this.getBalance();
  },
  mounted() {
    this.getBanners();
    this.getBalance();
    this.getCastingCalls();
  },
  methods: {
    redirectOption() {
      if (this.create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
    getCastingCalls() {
      casting_calls_services.getCastingCalls({}, 8).then((response) => {
        this.casting_calls = response.results;
        this.loaded_casting_calls = true;
      })
    },
    changeNet() {
      this.change = !this.change
    },
    openModal() {
      this.show_sider_network = true
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    referenceMember() {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'membership' }})
    },    
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    changeEye() {
      this.viewEye = !this.viewEye
    },
    getBalance() {
      service.getBalance().then(response => {
        this.total_balance = response.ready_balance
        this.balance_withdrawal = response.balance_withdrawal
        this.waiting_balance = response.waiting_balance;
        this.program_withdrawal_balance = response.program_withdrawal_balance
        this.total_ready = this.total_balance + this.waiting_balance + this.program_withdrawal_balance;
      })
    },
    getGroup() {
      const user = JSON.parse(localStorage.userData);
      this.groupRol = user.groups[0].name;
      this.hRol =
        this.groupRol === "creator" ? "height:250px" : this.width > 1400 || this.width < 1300 ? "height:350px" : "";
      this.campaignView = hasPermission('view_campaign');
    },
    getTitle() {
      return this.$t("dashboard.titleLead");
    },
    getVariant(participation) {
      if (participation === null || participation.status === "approved_lead")
        return "success";
      return "warning";
    },
    getVariantBg(info) {
      if (info.variant) return info.variant;
      else return "primary";
    },
    getBanners() {
      service.getBanners().then((response) => {
        response.forEach((banner) => {
          const obj = { url_file: "", image_file: "", position: "" };
          obj.url_file = banner.url_file;
          obj.image_file = service.getCore + banner.image_file;
          obj.position = banner.position;
          this.banners.push(obj);
        });
      });
    },
  },
  props: {},
};
</script>

<style>
.text-usd {
  color: #5e5873 !important;
  margin-left: 5px;
}
.container-metrics{
  margin-top: 22px;
  justify-content: center;
  font-weight: bold;
}
.metrics{
  font-weight: bold;
}
.without-border {
  border: none !important;
}
.icon-networks-connected {
  font-size: 15px;
  color: #fff !important;
  padding: 10px;
}
.icon-alert-profile{
  position:absolute;
  z-index: 2;
  top:19px;
  left:23px;
  font-size: 11px;
}
.without-border {
  border: none !important;
}
.hoverCard:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.header-card-creator {
  height: 188px;
}
.title-card {
  color: #0e131a;
}
.text-black {
  color: #6e6b7b;
}
.btn-blue-brandme {
  background: rgb(31, 86, 255);
  background: radial-gradient(
    circle,
    rgba(31, 86, 255, 1) 0%,
    rgba(0, 63, 255, 1) 45%
  );
  border-color: rgb(31, 86, 255) !important;
}
.container-cards-principal {
  justify-content: space-between;
}
.card-balance {
  padding-right: -80px;
}
.container-button-proposal {
  align-items: center;
  max-height: 80px;
}
.button-proposal {
  align-items: center;
  background-color: #55a6c4 !important;
  border:none;
}
.link-my-work {
  cursor: pointer;
  font-size: 1.714rem;
  font-weight: 500;
  line-height: 1.2;
  color: #5e5873;
}
.content-items {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 15px;
}
.items-content-balance{
  display: flex;
}
.rounded-card-up {
  border-radius: 5px 5px 0px 0px;
}
.rounded-card-down {
  display: flex;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  height: auto;
  padding: 0.5em;
  border-width: 1px;
  border: solid rgba(222,226,230,.7);
}
.text-body-price {
  color: #191919;
}
.campaign-tabs-principal-active-dashboard {
  font-weight: bold;
}
.campaign-tabs-principal {
  color: rgba(141,141,157,.56) !important;
  font-weight: bold;
}
#tabs-dashboad-creator .nav-tabs .nav-link {
  font-size: 14px;
}
</style>
<style scoped>
.container-loader-casting {
  border-radius: 0.75rem;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(60px);
  z-index: 1000;
  right: 5px;
}
.loader-casting {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-casting {
  margin-top: 10em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.box-casting-calls {
  background-color: #ececec;
  padding: 2em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.w-card-330-left, .w-card-330-right  {
  width: 24%;
  display: flex;
  flex-direction: column;
}
@media(max-width: 1350px) {
  .w-card-330-right {
    width: 100%;
  }
  .w-card-330-left {
    width: 35%;
  }
}
@media(max-width: 1000px) {
  .w-card-330-left {
    width: 100%;
  }
}
</style>