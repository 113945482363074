<template>
  <b-row class="m-0">
    <div class="backgroud-primary-absolute"></div>
    <b-row>
      <b-col class="col-12 col-lg-6 py-1">
        <auto-search></auto-search>
      </b-col>
     
      <card-marketing class="col-12 col-lg-6 py-1"
        :textBadge="$t('creator.convocateInfluencers')"
        :textInfo="$t('creator.convocateInfluencersText')"
        :textButton="$t('dashboard.create_casting_call')"
        :textLink="$t('creator.convocateInfluencersTextSmall')"
        :link="$t('creator.convocateInfluencersLink')"
        :img="getUrlCategory('influencer_BM')"
        :reference="avaible_plan ? { name: 'steps_casting_call', params: {step: 'select-service'}} : 'open_modal_membership'"
        :colorCard="'card-white'"
      >
      </card-marketing>
    </b-row>

    <b-row class="w-100 m-0">
      <b-col class="col-12 mt-2 w-100 p-0">
        <h2 class="text-white">{{ $t('header.menu.my_work') }}</h2>
  
        <b-tabs 
          class="nav-brands-dashboard"
          active-nav-item-class="text-white underline-nav-item"
        >
          <b-tab title="Overview" active>
            <brand-overview :avaible_plan="avaible_plan"></brand-overview>
          </b-tab>
          <b-tab :title="$t('creator.myOrders')" @click="$router.push({ name: 'my_work_brands', params: { section: 'orders' } }).catch(() => {})"></b-tab>
          <b-tab :title="$t('creator.campaignsExternal')" @click="$router.push({ name: 'my_work_brands', params: { section: 'campaigns' } }).catch(() => {})"></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-row>

</template>
  
<script>
import { 
  BRow, 
  BCol, 
  BTab,
  BTabs,
} from "bootstrap-vue";
import { getUserData } from '@/libs/utils/user'
import { getUrlCategory } from '@/libs/utils/urls';

export default {
  name: "DashboardBrand",
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,  
    AutoSearch: () => import('@/views/search/search.vue'),
    brandOverview: () => import('./brandOverview.vue'),
    cardMarketing: () => import('@/views/components/proposals/cardMarketing.vue'),
  },
  data() {
    return {
      getUserData,
      getUrlCategory,
    };
  },
  computed: {
    avaible_plan() {
      return getUserData().current_plan.create_casting_call
    },
  },
};
</script>
<style>
.banner-leads .card-body {
  display: flex;
  justify-content: flex-start;
}
.icon-leads {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0;
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  min-width: 40px;
} 
.hoverCard:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.header-card-creator {
  height: 188px;
}
.text-black {
  color: #6e6b7b;
}
.nav-brands-dashboard .nav-tabs .nav-link {
  color: #cdcdcd;
  padding: 0;
  margin: 1em 1em 0 0;
  font-weight: bolder;
}
.underline-nav-item {
  border-bottom: 3px solid white !important;
}
</style>
<style lang="scss" scoped> 
.card-convocate {


  .images-inner-first {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: -4em;
  }
}
.backgroud-primary-absolute {
  background: rgb(125,42,231);
  background: -moz-linear-gradient(173deg, rgba(125,42,231,1) 14%, rgba(0,196,204,1) 97%);
  background: -webkit-linear-gradient(173deg, rgba(125,42,231,1) 14%, rgba(0,196,204,1) 97%);
  background: linear-gradient(173deg, rgba(125,42,231,1) 14%, rgba(0,196,204,1) 97%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7d2ae7",endColorstr="#00c4cc",GradientType=1);  position: absolute;
  top: 11.5em;
  left: 0;
  height: 700px;
  width: 100%;
}
</style>